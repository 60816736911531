import React from "react"
import "../styles/logo.css"
import "../components/Layout"
import Navigation from "../components/Navigation"
import Includes from "../components/Includes"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Portfolio from "../components/Portfolio"



export default function portfolio() {
  return (<>
            <Header/>
            <Navigation/>
            <br></br>
            <main id="main">
            <Portfolio/>
            
            </main>
            <Includes/>
            <Footer/>

        
  </>)
}